import React, { useEffect, useState } from 'react'
import { automationTemplates } from '@vidday/data'
import { getProfileName, handleCopy, ordinalSuffixOf } from '@vidday/utils'
import { updateEvent, addRenderJob } from '../../../api/admin/events'
import { postPublishedEmail } from '../../../api/actions'
import { compressViddayPackage } from '../../../api/admin/workers/vidday-package'
import { addFlashMessage } from '../../../api/app/behaviors/flash-messages'
import { regenerateImage } from '../../../api/app/media'
import { useDispatch } from 'react-redux'
import {
	Tag,
	Box,
	HStack,
	Icon,
	Divider,
	Text,
	Button,
	Flex,
	Tab,
	Tabs,
	TabList,
	TabPanels,
	TabPanel,
	VStack,
} from '@chakra-ui/react'
import {
	AccountCircleRounded,
	EmailRounded,
	SettingsSuggestRounded,
	NotesRounded,
	MovieRounded,
} from '../../../v2/ui/atoms/Icon'
import NotesForm from './NotesForm'
import EventRenderJob from './EventRenderJob'
import MediaPackageForm from './MediaPackageForm'
import { totalVideoLength } from '@vidday/utils'
import EventTab from './EventTab'

const EventActionsBox = ({ data, media, medias, recipients, events, renderJobs, publishedMedia, socket }) => {
	const dispatch = useDispatch()
	const [currentTheme, setCurrentTheme] = useState(null)

	const handleStartRender = () => {
		let postData = {
			uuid: data.uuid,
			preview: false,
			template: currentTheme.id, // here template is just the theme id
		}
		console.log('Starting [FULL] Render Job for Event :', postData)
		dispatch(addRenderJob(postData))
	}

	/* Deprecated once automation is online */
	const handleMediaPackage = (eventUuid) => {
		dispatch(compressViddayPackage(eventUuid))
	}

	const handleCreatePreviewRenderJob = () => {
		let postData = {
			uuid: data.uuid,
			preview: true,
		}
		console.log('Starting [PREVIEW] Render Job for Event :', postData)
		dispatch(addRenderJob(postData))
	}

	const handleCreateFullRenderJob = () => {
		let postData = {
			uuid: data.uuid,
			preview: false,
		}
		console.log('Starting [FULL] Render Job for Event :', postData)
		dispatch(addRenderJob(postData))
	}

	const handleEventDownload = (eventId) => {
		// Get event from props
		let fullEvent = events.filter(function (ev) {
			return ev.id === eventId
		})[0]

		// Add extra fields to contain nested media and nested recipients
		fullEvent.mediaFull = []
		fullEvent.recipientsFull = []

		// Grab full media objects and insert
		fullEvent.media.forEach(function (mediaId, index) {
			fullEvent.mediaFull[index] = medias[mediaId]
		})

		// Grab full media objects and insert
		fullEvent.recipients.forEach(function (recipientId, index) {
			fullEvent.recipientsFull[index] = recipients[recipientId]
		})

		// stringify result to JSON
		let json = JSON.stringify(fullEvent, null, 4) // spacing of 4
		let data = 'text/json;charset=utf-8,' + encodeURIComponent(json) // encode file

		// create temporary anchor on document to emulate a download click
		let a = document.createElement('a')
		a.href = 'data:' + data
		a.download = 'event_' + fullEvent.uuid + '.json'
		a.click()
		a.remove()
	}

	const handlePublishedNotification = (id) => {
		dispatch(postPublishedEmail({ id: data.id }))
	}

	const handleResetOccupied = () => {
		let postData = {
			id: data.key || data.id,
			occupied: false,
		}
		// Change occupied:false
		dispatch(updateEvent(postData))
	}

	const handleRegenAllImages = () => {
		const images = media.filter((med) => med.mimeType.indexOf('image') > -1)

		if (images) dispatch(addFlashMessage('info', 'Regenerating all images...'))

		let requests = images.map(function (image) {
			const imageData = { data: { ...image, croppedLocation: image.location, occupied: true } }
			const muteAlert = true
			return new Promise((resolve) => {
				dispatch(regenerateImage(imageData, muteAlert, resolve))
			})
		})

		Promise.all(requests).then(() => dispatch(addFlashMessage('success', 'All image processing complete.')))
	}

	useEffect(() => {
		setCurrentTheme(
			data.themeTemplateId
				? automationTemplates.filter((theme) => theme.id == data.themeTemplateId)[0]
				: automationTemplates.filter((theme) => theme.default)[0]
		)
	}, [])

	useEffect(() => {
		if (data) {
			setCurrentTheme(automationTemplates.filter((theme) => theme.id == data.themeTemplateId)[0])
		}
	}, [data])

	/**
	 * Format Data
	 */
	const eventAdmin = data.creator && data.creator.profile ? getProfileName(data.creator.profile, true) : null
	const eventAdminEmail = data.creator ? data.creator.email : null
	const eventId = data.id || null
	// const formattedRecipients = recipients ? getRecipients(recipients, true).names : ''
	var occasion = data.occasion.charAt(0).toUpperCase() + data.occasion.slice(1).toLowerCase()
	const nthYears = ordinalSuffixOf(data.years)
	const title = `${data.uuid} - ${nthYears} ${occasion} VidDay for ${recipients.formatted}`
	const titleHtml = (
		<>
			<strong>
				{data.uuid} - {nthYears} {occasion}
			</strong>
			&nbsp; VidDay for &nbsp;<strong>{recipients.formatted}</strong>
		</>
	)
	var themeLabel = currentTheme && currentTheme.label ? currentTheme.label : ''

	var videoLength = totalVideoLength(media, true, data.themeTemplateId) || ''

	return (
		<Box w="full" boxShadow="base">
			<Flex
				direction="column"
				sx={{
					alignItems: 'center',
					justifyContent: 'space-between',
					w: 'full',
					p: '1rem',
					background: 'rgb(243,244,248)',
					background: 'linear-gradient(180deg, rgba(243,244,248,1) 0%, rgba(255,255,255,1) 100%)',
				}}>
				<HStack alignItems="center">
					<Text color="gray.500">Creator:</Text>
					<Text
						color="gray.700"
						display="flex"
						alignItems="center"
						onClick={(e) => handleCopy(e, eventAdmin, true)}>
						<AccountCircleRounded mr="0.25rem" />
						{eventAdmin}
					</Text>
					<Text
						color="gray.700"
						display="flex"
						alignItems="center"
						onClick={(e) => handleCopy(e, eventAdminEmail, true)}>
						<EmailRounded mr="0.25rem" />
						{eventAdminEmail}
					</Text>
				</HStack>

				<HStack alignItems="center">
					<Text color="gray.500">Event:</Text>
					<Text color="gray.700" onClick={(e) => handleCopy(e, title, true)}>
						{titleHtml}
					</Text>
				</HStack>

				<HStack alignItems="center">
					<Text color="gray.500">Event Id:</Text>
					<Text color="gray.700" onClick={(e) => handleCopy(e, eventId, true)}>
						{eventId}
					</Text>
				</HStack>
			</Flex>
			<Flex
				direction="column"
				sx={{
					alignItems: 'center',
					justifyContent: 'space-between',
					w: 'full',
					p: '1rem',
					background: 'rgb(243,244,248)',
					background: 'linear-gradient(180deg, rgba(243,244,248,1) 0%, rgba(255,255,255,1) 100%)',
				}}>
				<HStack>
					<Button size="xs" variant="download" onClick={() => handleResetOccupied(data.key)}>
						Reset Occupied Event
					</Button>
					<Button size="xs" variant="download" onClick={() => handlePublishedNotification(data.key)}>
						Re-send Published Email
					</Button>
					<Button size="xs" variant="download" onClick={() => handleRegenAllImages(data.key)}>
						Regen All Images
					</Button>
					<Button size="xs" variant="download" onClick={() => handleMediaPackage(data.uuid)}>
						Start Media Package
					</Button>
				</HStack>
			</Flex>
			{/* <Divider variant="dashed" my="1rem" /> */}
			<Box w="full" bg="white" p="1rem">
				<Tabs variant="enclosed">
					<TabList>
						<Tab>
							<SettingsSuggestRounded w="1.5rem" mr="0.5rem" /> Renders
							{renderJobs?.length > 0 && (
								<Tag size="sm" variant="solid" colorScheme="pink" fontWeight="bold" ml="0.5rem">
									{renderJobs.length}
								</Tag>
							)}
						</Tab>
						<Tab>Event</Tab>
						<Tab>
							<MovieRounded w="1.5rem" mr="0.5rem" /> Media
						</Tab>
						<Tab>
							<NotesRounded w="1.5rem" mr="0.5rem" /> Notes
						</Tab>
					</TabList>

					<TabPanels>
						<TabPanel>
							<Flex w="full" justify="space-between" align="center">
								<HStack alignItems="center">
									<Text color="gray.500">Current theme:</Text>
									<Text color="gray.700" fontWeight="bold">
										{themeLabel}
									</Text>
								</HStack>
								<Button type="submit" size="xs" variant="download" onClick={handleStartRender}>
									Start Render
								</Button>
							</Flex>

							<Divider variant="dashed" my="1rem" />

							<VStack w="full">
								{renderJobs &&
									renderJobs.map((job, index) => (
										<EventRenderJob
											key={index}
											// {...this.props}
											job={job}
										/>
									))}
							</VStack>
						</TabPanel>

						<TabPanel>
							<EventTab data={data} publishedMedia={publishedMedia} videoLength={videoLength} />
						</TabPanel>

						<TabPanel>
							<MediaPackageForm data={data} />
							{/* <MediaForm data={data} /> */}
						</TabPanel>

						<TabPanel>
							<NotesForm data={data} />
						</TabPanel>
					</TabPanels>
				</Tabs>
			</Box>
		</Box>
	)
}

export default EventActionsBox
